<template>
  <v-container
    id="admin-packageMedia-view"
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-package"
    >
      <template #title>
        {{ $t('layout.sidebar.packageMedia') }}
      </template>
      <header-form />
      <table-package-media />
    </material-card>
    <dialog-package-media-form-update />
  </v-container>
</template>

<script>
  import HeaderForm from '../modules/admin/packageMedia/components/HeaderForm'
  import TablePackageMedia from '../modules/admin/packageMedia/components/TablePackageMedia'
  import DialogPackageMediaFormUpdate from '../modules/admin/packageMedia/components/DialogPackageMediaFormUpdate'

  export default {
    name: 'AdminPackageMedia',
    components: {
      HeaderForm,
      TablePackageMedia,
      DialogPackageMediaFormUpdate,
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Admin Package Media',
    },
  }
</script>
