<template>
  <v-dialog
    v-if="isShowDialogPackageMediaForm"
    v-model="isShowDialogPackageMediaForm"
    persistent
    max-width="90%"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ dataForm.id ? "Edit" : "Add" }} Package Media</span
          >
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="dataForm.title"
            :rules="fieldRules"
            label="Title"
            type="text"
            outlined
            dense
          />
          <v-text-field
            v-model="dataForm.mimeTypeImage"
            :rules="fieldRules"
            type="text"
            label="Mime Type Image"
            outlined
            dense
          />
          <v-row class="mb-2">
            <v-col>
              <v-text-field
                v-model="dataForm.maxFileSizeImage"
                :rules="fieldRules"
                label="Max File Size Image"
                type="number"
                outlined
                dense
                hide-details
              >
                <template v-slot:append>
                  <v-select
                    hide-details
                    class="pa-3 v-select_format"
                    underlined
                    v-model="maxFileSizeImageUnit"
                    :label="$t('packageMedia.packageMediaForm.form.unit')"
                    :items="['MB', 'GB']"
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            v-model="dataForm.mimeTypeVideo"
            :rules="fieldRules"
            type="text"
            label="Mime Type Video"
            outlined
            dense
          />
          <v-row class="mb-2">
            <v-col>
              <v-text-field
                v-model="dataForm.maxFileSizeVideo"
                :rules="fieldRules"
                label="Max File Size Video"
                type="number"
                outlined
                dense
                hide-details
              >
                <template v-slot:append>
                  <v-select
                    hide-details
                    class="pa-3 v-select_format"
                    underlined
                    :label="$t('packageMedia.packageMediaForm.form.unit')"
                    v-model="maxFileSizeVideoUnit"
                    :items="['MB', 'GB']"
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            v-model="dataForm.mimeTypeDocument"
            :rules="fieldRules"
            type="text"
            label="Mime Type Document"
            outlined
            dense
          />
          <v-row>
            <v-col>
              <v-text-field
                v-model="dataForm.maxFileSizeDocument"
                :rules="fieldRules"
                label="Max File Size Document"
                type="number"
                outlined
                dense
                hide-details
              >
                <template v-slot:append>
                  <v-select
                    hide-details
                    class="pa-3 v-select_format"
                    underlined
                    v-model="maxFileSizeDocumentUnit"
                    :label="$t('packageMedia.packageMediaForm.form.unit')"
                    :items="['MB', 'GB']"
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="dataForm.maxTotalFileSizeMedia"
                :rules="fieldRules"
                label="Max Total File Size Media"
                type="number"
                outlined
                dense
                hide-details
              >
                <template v-slot:append>
                  <v-select
                    hide-details
                    class="pa-3 v-select_format"
                    underlined
                    v-model="maxTotalFileSizeMediaUnit"
                    :label="$t('packageMedia.packageMediaForm.form.unit')"
                    :items="['MB', 'GB']"
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            @click="isShowDialogPackageMediaForm = false"
            v-text="$t('user.userForm.actions.close')"
          />
          <v-btn
            color="info"
            :disabled="!valid"
            @click="save"
            v-text="$t('user.userForm.actions.save')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
// Vuex
import { sync } from "vuex-pathify";
export default {
  name: "DialogPackageMediaFormUpdate",
  data: () => ({
    valid: true,
    titleRules: [
      (v) => !!v || "Title is required",
      (v) => (v && v.length <= 255) || "Max 255 characters",
    ],
    fieldRules: [
      (v) => !!v || 'Field is required',
    ],
    maxFileSizeImageUnit: "",
    maxFileSizeVideoUnit: '',
    maxFileSizeDocumentUnit: '',
    maxTotalFileSizeMediaUnit: '',
  }),
  computed: {
    ...sync("adminPackageMedia", [
      "isShowDialogPackageMediaForm",
      "dataForm",
      "params",
    ]),
  },
  mounted() {},
  watch: {
    isShowDialogPackageMediaForm() {
      this.formatValueToGbOrMb();
    },
  },
  methods: {
    formatValueToGbOrMb() {
      this.maxFileSizeImageUnit = this.fileSize(this.dataForm.maxFileSizeImage).unit;
      this.dataForm.maxFileSizeImage = this.fileSize(this.dataForm.maxFileSizeImage).res;

      this.maxFileSizeVideoUnit = this.fileSize(this.dataForm.maxFileSizeVideo).unit;
      this.dataForm.maxFileSizeVideo = this.fileSize(this.dataForm.maxFileSizeVideo).res;

      this.maxFileSizeDocumentUnit = this.fileSize(this.dataForm.maxFileSizeDocument).unit;
      this.dataForm.maxFileSizeDocument = this.fileSize(this.dataForm.maxFileSizeDocument).res;

      this.maxTotalFileSizeMediaUnit = this.fileSize(
        this.dataForm.maxTotalFileSizeMedia
      ).unit;
      this.dataForm.maxTotalFileSizeMedia = this.fileSize(
        this.dataForm.maxTotalFileSizeMedia
      ).res;
    },
    formatValueToGbOrMbNoChange() {
      this.dataForm.maxFileSizeImage = this.fileSizeNoChange(
        this.dataForm.maxFileSizeImage,
        this.maxFileSizeImageUnit
      );

      this.dataForm.maxFileSizeVideo = this.fileSizeNoChange(
        this.dataForm.maxFileSizeVideo,
        this.maxFileSizeVideoUnit
      );


      this.dataForm.maxFileSizeDocument = this.fileSizeNoChange(
        this.dataForm.maxFileSizeDocument,
        this.maxFileSizeDocumentUnit
      );
    
      this.dataForm.maxTotalFileSizeMedia = this.fileSizeNoChange(
        this.dataForm.maxTotalFileSizeMedia,
        this.maxTotalFileSizeMediaUnit
      );
    },
    take_decimal_number(num = 0, n) {
      const base = 10 ** n;
      const result = Math.round(num * base) / base;
      return result;
    },
    fileSize(data) {
      if (!data) { return 0 }
      const convertMB = (data * 0.001) / 1024;
      let result = this.take_decimal_number(convertMB, 1);
      let unit = "MB";
      if (result > 1024 || result === 1024) {
        result = result / 1024;
        unit = "GB";
      }
      let res = result;
      if (res < 1) {
        res = 1;
      }
      return {
        res: res,
        unit: unit,
      };
    },
    fileSizeNoChange(data, unit) {
      const convertMB = (data * 0.001) / 1024;
      const result = "";
      if (unit === "MB") {
        this.result = this.take_decimal_number(convertMB, 1);
      }
      if (unit === "GB") {
        this.result = this.take_decimal_number(convertMB, 1);
        this.result = this.result / 1024;
      }
      let res = this.result;
      if (res < 1) {
        res = 1;
      }
      return res;
    },
    convertValueToKb(data, dataUnit) {
      let res = 0;
      if (data && dataUnit === "MB") {
        res = (data * 1024) / 0.001;
      } else {
        res = (data * 1048576) / 0.001;
      }
      return res;
    },
    async save() {
      const dataFormNoChange = this.dataForm;
      const maxFileSizeImageUnitNoChange = this.maxFileSizeImageUnit;
      const maxFileSizeDocumentUnitNoChange = this.maxFileSizeDocumentUnit;
      const maxFileSizeVideoUnitNoChange = this.maxFileSizeVideoUnit;
   
      const maxTotalFileSizeMediaUnitNoChange = this.maxTotalFileSizeMediaUnit;

      this.dataForm.maxFileSizeImage = this.convertValueToKb(
        this.dataForm.maxFileSizeImage,
        this.maxFileSizeImageUnit
      );

      this.dataForm.maxFileSizeDocument = this.convertValueToKb(
        this.dataForm.maxFileSizeDocument,
        this.maxFileSizeDocumentUnit
      );

      this.dataForm.maxFileSizeVideo = this.convertValueToKb(
        this.dataForm.maxFileSizeVideo,
        this.maxFileSizeVideoUnit
      );
      
      this.dataForm.maxTotalFileSizeMedia = this.convertValueToKb(
        this.dataForm.maxTotalFileSizeMedia,
        this.maxTotalFileSizeMediaUnit
      );
      const valueCheck = this.$refs.form.validate();

      if (!valueCheck) {
        this.formatValueToGbOrMbNoChange();
        return;
      }
      const dataPackageMediaUpdateResult = await this.$store.dispatch(
        `adminPackageMedia/${
          !this.dataForm.id ? "create" : "update"
        }PackageMedia`,
        this.getTokenLogin()
      );
      if (dataPackageMediaUpdateResult.status === false) {
        this.dataForm = dataFormNoChange;
        this.maxFileSizeImageUnit = maxFileSizeImageUnitNoChange;
        this.maxFileSizeDocumentUnit = maxFileSizeDocumentUnitNoChange;
        this.maxFileSizeVideoUnit = maxFileSizeVideoUnitNoChange;

        this.maxTotalFileSizeMediaUnit = maxTotalFileSizeMediaUnitNoChange;
        this.formatValueToGbOrMbNoChange();
        if (dataPackageMediaUpdateResult.data.length > 0) {
          if (typeof dataPackageMediaUpdateResult.data === "string") {
            this.$toast.error(dataPackageMediaUpdateResult.data);
          } else {
            dataPackageMediaUpdateResult.data.forEach((element) => {
              const keyObject = Object.keys(element);
              const messageError = `packageMedia.packageMediaForm.notifications.error['${element[keyObject].code}']`;
              this.$toast.error(keyObject + ": " + this.$t(messageError));
            });
          }
        } else {
          this.$toast.error(dataPackageMediaUpdateResult.data.message);
        }
        return;
      }
      this.formatValueToGbOrMb();
      this.$toast.success(
        `${!this.dataForm.id ? "Created" : "Updated"} Package Media Success`
      );
      setTimeout(() => {
        if (this.params.offset !== 1) {
          this.params.offset = 1;
        } else {
          this.$store.dispatch(
            "adminPackageMedia/fetchDataPackageMediaList",
            this.getTokenLogin()
          );
        }
        this.isShowDialogPackageMediaForm = false;
      }, 1000);
    },
  },
};
</script>
<style scoped>
.v-input--selection-controls {
  width: 145px;
  margin-top: 0;
  padding-top: 0;
}
.v-input--selection-controls
  .v-input__control
  .v-input__slot
  .v-input--radio-group__input
  > .v-radio
  > .v-label {
  position: inherit;
}
.v-radio > .v-label {
  position: inherit;
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
}
.v-text-field .v-label {
  color: red !important;
}
.v-input--radio-group__input {
}
.v-input__append-inner {
  margin-top: 0 !important;
}
.v-input--selection-controls .v-input__slot > .v-label {
}
label {
  position: inherit;
}
.v-input__append .v-select {
  margin-top: 0 !important;
}
.v-select_format {
  margin-top: -0.5rem;
}
</style>
