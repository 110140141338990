<template>
  <v-row class="px-4">
    <v-col cols="12" class="text-end">
      <v-btn
        color="success"
        @click="clickAddPackageMedia"
        v-text="$t('user.userList.actions.add')"
      />
    </v-col>
  </v-row>
</template>

<script>
// Vuex
import { sync } from "vuex-pathify";
export default {
  name: "HeaderForm",
  computed: {
    ...sync("adminPackageMedia", ["isShowDialogPackageMediaForm"]),
    ...sync("adminPackageMedia", [
      "isShowDialogPackageMediaForm",
      "dataForm",
      "params",
    ]),
  },
  methods: {
    take_decimal_number(num, n) {
      const base = 10 ** n;
      const result = Math.round(num * base) / base;
      return result;
    },
    fileSize(data) {
      const convertMB = (data * 0.001) / 1024;
      const result = "";
      // const unit = ''
      const sizeIsTooSmall = null;
      const sizeIsTooSmallunit = null;
      // console.log(convertMB)
      // this.result = convertMB
      this.result = this.take_decimal_number(convertMB, 1);
      // this.unit = 'MB'
      if (this.result > 1024 || this.result === 1024) {
        this.result = this.result / 1024;
        // this.unit = 'GB'
      }
      const res = this.result;
      return res;
    },
    clickAddPackageMedia() {
      this.$store.dispatch("adminPackageMedia/setDefaultFormDataPackageMedia");
      this.isShowDialogPackageMediaForm = true;
      // this.dataForm.maxFiles = 2
    },
  },
};
</script>

<style scoped></style>
